import React from "react";
import styles from "./index.module.css";
import QrCode from "../components/qr-code";


// markup
const InitPage = () => {


  return (
    <main>
      <title>Home Page</title>
      <div className={styles.wrapper}>
        <QrCode/>
      </div>
    </main>
  )
}

export default InitPage
