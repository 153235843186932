import * as React from "react"
import styles from "./index.module.css";
import logo from '../static/logo.jpg';


// markup
const EmptyPage = () => {
  return (
    <main>
      <title>Home Page</title>
      <div className={styles.wrapper}>
        <img src={logo} className={styles.logo} alt="Lavizor" />
      </div>
    </main>
  )
}

export default EmptyPage
