import React from 'react';

const ImageUpload = ({ onChange }) => {

    return (
       <input type="file" accept="image/*" onChange={onChange}/>
    );
};

export default ImageUpload;
