
import React, { useEffect } from "react"
import firebase from '../../core/firebase';
import Loader from '../loader';
import { useCookies } from 'react-cookie';
import { useParams, useHistory } from "react-router-dom";

const ShortLink = () => {
  const history = useHistory();
  const { linkId } = useParams();
  const [, setCookie] = useCookies(['link']);

  useEffect(() => {
    const effect = async () => {
      const shortLink = await firebase.shortLinks.getShortLink(linkId);
      const data = shortLink.data();
      setCookie('link', data, { path: '/', maxAge: 2629746 });
      if (data) {
        if (data.version_id) {
          window.location = `/${data.location_id}/version/${data.version_id}`;
        }
        else {
          window.location = `/${data.location_id}`;
        }
      }
      else {
        window.location = `/`;
      }
    }
    effect();
  }, [ linkId, setCookie, history ]);

  return <>
    <Loader />
  </>
}

export default ShortLink