
import React, { useEffect } from "react"
import firebase from '../../core/firebase';
import Loader from '../loader';
import { useCookies } from 'react-cookie';
import { useParams, useHistory } from "react-router-dom";
import { nanoid } from 'nanoid';

const QrCode = () => {
  const history = useHistory();
  const { qrCodeId } = useParams();
  const [, setCookie] = useCookies(['link','qr']);

  useEffect(() => {
    const effect = async () => {
      const qrCode = await firebase.qrCodes.getQrCode(qrCodeId);
      const data = qrCode.data();
      setCookie('qr', { ...data, id: nanoid(30) }, { path: '/', maxAge: 2629746 });
      if (data) {
        if (data.version_id) {
          window.location = `/${data.location_id}/version/${data.version_id}`;
        }
        else {
          window.location = `/${data.location_id}`;
        }
      }
      else {
        window.location = `/`;
      }
    }
    effect();
  }, [ qrCodeId, setCookie, history ]);

  return <>
    <Loader />
  </>
}

export default QrCode