import React, { useEffect } from "react"
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import ResultNegativePage from './pages/result-negative';
import ResultNeutralPage from './pages/result-neutral';
import ResultPage from './pages/result';

import HomePage from './pages/index';

import PollNegativePage from './pages/poll-negative';
import PollNeutralPage from './pages/poll-neutral';
import PollAfterLinkPage from './pages/poll-after-link';

import ThankYouPage from './pages/thank-you';
import EmptyPage from './pages/empty';
import ShortLinkPage from './pages/short-link';
import QrCodePage from './pages/qr-code';
import ym from 'react-yandex-metrika';
import './App.css';

function App() {
	let location = useLocation();
	

	useEffect(
	  () => {
		ym('hit', location.pathname);
	  },
	  [location]
	)
  return (
		<Switch>
			<Route exact path="/u/:linkId" component={ShortLinkPage} />
			<Route exact path="/qr/:qrCodeId" component={QrCodePage} />
			<Route exact path="/:locationId/" component={HomePage} />
			<Route exact path="/:locationId/version/:versionId" component={HomePage} />
			<Route exact path="/:locationId/thank-you" component={ThankYouPage} />
			<Route exact path="/:locationId/version/:versionId/thank-you" component={ThankYouPage} />

			{/* poll pages */}
			<Route exact path="/:locationId/poll/negative" component={PollNegativePage} />
			<Route exact path="/:locationId/version/:versionId/poll/negative" component={PollNegativePage} />
			<Route exact path="/:locationId/poll/neutral" component={PollNeutralPage} />
			<Route exact path="/:locationId/version/:versionId/poll/neutral" component={PollNeutralPage} />
			<Route exact path="/:locationId/poll/links" component={PollAfterLinkPage} />
			<Route exact path="/:locationId/version/:versionId/poll/links" component={PollAfterLinkPage} />

			{/* result pages */}
			<Route exact path="/:locationId/result" component={ResultPage} />
			<Route exact path="/:locationId/version/:versionId/result" component={ResultPage} />
			<Route exact path="/:locationId/result/negative" component={ResultNegativePage} />
			<Route exact path="/:locationId/version/:versionId/result/negative" component={ResultNegativePage} />
			<Route exact path="/:locationId/result/neutral" component={ResultNeutralPage} />
			<Route exact path="/:locationId/version/:versionId/result/neutral" component={ResultNeutralPage} />
			<Route path="*" component={EmptyPage} />
		</Switch>
  );
}

export default App;
