export default class ShortLinks {
    constructor(firebase) {
        this.firebase = firebase;
    }

    getQrCode = async (qrCodeId) => {
        if (qrCodeId) {
            return this.firebase.db
                .collection('qr-codes')
                .doc(qrCodeId)
                .get();
        }

        return Promise.reject(new Error('Missing parameter "qrCodeId"'));
    }
}
