import React, { useEffect } from "react"
import Poll from '../components/poll';
import Logo from '../components/logo';
import styles from "./index.module.css";
import { useParams, useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { nanoid } from 'nanoid';
import ym from 'react-yandex-metrika';
import firebase from '../core/firebase';


// markup
const IndexPage = () => {
  const { locationId, versionId } = useParams();
  const history = useHistory();

  const [cookie, setCookie] = useCookies(['link','qr']);
  useEffect(() => {
    const effect = () => {
      if (cookie.link == null) {
        setCookie('link', { id: nanoid(6) }, { path: '/' });
      }
    }
    effect();
  }, [ cookie, setCookie ]);
  useEffect(() => {
    if (locationId) {
      ym('params', { location: { id: locationId } })
      if (versionId) {
        firebase.versions.getVersion(versionId).then((version) => {
          if (!version.IsLoading) {
            ym('params', { location: { id: locationId, version: { name: version.name, id: versionId }  } })
          }
        });
      }
    }
  }, [ locationId, versionId ]);

			//
  /*
  */
  return (
    <main>
      <title>Home Page</title>
      <div className={styles.wrapper}>
        <Logo className={styles.logo}/>
        <Poll 
          onPositive={() => { 
            if (versionId) {
              history.push(`/${locationId}/version/${versionId}/thank-you`);
            }
            else {
              history.push(`/${locationId}/thank-you`);
            }
          }}
          onNegative={() => { 
            if (versionId) {
              history.push(`/${locationId}/version/${versionId}/poll/negative`);
            }
            else {
              history.push(`/${locationId}/poll/negative`);
            }
          }}
          onNeutral={() => { 
            if (versionId) {
              history.push(`/${locationId}/version/${versionId}/poll/neutral`);
            }
            else {
              history.push(`/${locationId}/poll/neutral`);
            }
          }}
        />
      </div>
    </main>
  )
}

export default IndexPage
