import { Lavizor } from '../sdk';
import { utils } from '../../helpers';

export default class Responses{
    constructor(firebase) {
        this.firebase = firebase;
    }
    createResponse = async (locationId, data) => {
        const responseRef = this.firebase.db
            .collection('responses').doc();

        const response = {
            ...data,
            id: responseRef.id,
            location_id: locationId,
            created_at: Lavizor.getTimestampFieldValue()
        };

        responseRef.set(response, { merge: true });

        return responseRef;
    }
    createFileResponse = async (locationId, data, imageAsFile) => {
        // Prepend random string to make files unique. Firebase has issues with files with the same name but having different tokens
        // Reference: https://stackoverflow.com/a/47738367
        const fileName = utils.generateFileName({ prefix: `${locationId}_`, contentType: imageAsFile.type });
        const path = `location-files/${fileName}`;
        const uploadTask = this.firebase.storage.ref(`/${path}`).put(imageAsFile);

        uploadTask.on(
            'state_changed',
            () => {
            },
            (err) => {
                // catches the errors
                console.log(err);
            },
            () => {
                // gets the functions from storage refences the image storage in Firebase by the children
                // gets the download url then sets the image from firebase as the value for the imgUrl key:
                this.firebase.storage
                    .ref('location-files')
                    .child(fileName)
                    .getDownloadURL()
                    .then((imgUrl) => {
                        this.createResponse(locationId, {
                            ...data,
                            answer: imgUrl
                        });
                    });
            }
        );
    }
}
