
import React, { useState } from "react"
import firebase from '../../core/firebase';
import { useParams } from "react-router-dom";
import styles from "./index.module.css";
import { useCookies } from 'react-cookie';
import * as moment from 'moment';

const Bonuses = ({ label }) => {
  const { locationId, versionId } = useParams();
  const [bonuses, setBonuses] = useState('');
  const [cookies, ] = useCookies(['link']);

  firebase.locations.theme.getTheme(locationId, versionId).then((theme) => {

    if (!theme.IsLoading) {
      moment.locale('ru');
      if (theme[label] ) {
          let b = theme[label].replace('{0}',cookies.link?.data?.number ?? 'незвестен');
          b = b.replace('{1}',moment().add(91, 'days').format('DD.MM.yyyy'))
          setBonuses(b);
      }
    }
  });

    return bonuses && <div className={styles.wrapper}>
      {bonuses}
    </div>
}

export default Bonuses