import React, { useEffect, useState } from "react"
import Links from '../components/links';
import Bonuses from '../components/bonuses';
import Terms from '../components/terms';
import styles from "./thank-you.module.css"
import success from '../static/success.svg';
import ym from 'react-yandex-metrika';
import Parser from 'html-react-parser';

import firebase from '../core/firebase';
import { useParams, useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';


// markup
const IndexPage = () => {
  const history = useHistory();
  const [cookies, ] = useCookies(['link','qr']);

  const { locationId, versionId } = useParams();
  const [thankYouSubtitle, setThankYouSubtitle] = useState('');
  const [thankYouTitle, setThankYouTitle] = useState('');

  firebase.locations.theme.getTheme(locationId, versionId).then((theme) => {

    if (!theme.IsLoading) {
      let subtitle = 'Будем благодарны если Вы оставите отзыв на независимом ресурсе';
      if (theme.thank_you_subtitle) {
        subtitle = theme.thank_you_subtitle;
      }
      setThankYouSubtitle(subtitle);
      if (theme.thank_you_title) {
        setThankYouTitle(theme.thank_you_title);
      }
    }
  });

  useEffect(() => {
    ym('reachGoal','positive')
  }, []);

  return (
    <main>
      <title>Home Page</title>

      <Bonuses label="bonuses"/>
      <div className={styles.wrapper}>
        <img src={success} className={styles.logo} alt=""/>
        <h2 className={styles.heading}>{Parser(thankYouTitle)}</h2>
        <div className={styles.text}>
        {Parser(thankYouSubtitle)}
        </div>
        <Links onClick={async (link)=> {

            const data = {
              type: 'link-click',
              link: link,
              reference_id: cookies.qr?.id ?? cookies.link?.id,
              user: cookies.link?.data ?? {},
              qr: cookies.qr ?? {}
          }
          await firebase.responses.createResponse(locationId, data);

          ym('reachGoal','link-click')
          window.open(link.url, "_blank");


            if (versionId) {
              history.push(`/${locationId}/version/${versionId}/poll/links`);
            }
            else {
              history.push(`/${locationId}/poll/links`);
            }
          }}
        />
      </div>
      <Terms/>


    </main>
  )
}

export default IndexPage
