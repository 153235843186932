export default class ShortLinks {
    constructor(firebase) {
        this.firebase = firebase;
    }

    getShortLink = async (linkId) => {
        if (linkId) {
            return this.firebase.db
                .collection('short-links')
                .doc(linkId)
                .get();
        }

        return Promise.reject(new Error('Missing parameter "linkId"'));
    }
}
