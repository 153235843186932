import React, { useEffect, useState } from "react"
import Poll from '../components/poll';
import { useHistory } from "react-router-dom";
import styles from "./index.module.css"
import { useParams } from "react-router-dom";
import Logo from '../components/logo';
import Bonuses from '../components/bonuses';
import Terms from '../components/terms';
import ym from 'react-yandex-metrika';
import { useQuestions } from '../core/hooks';

// markup
const PollNegativePage = () => {
  const { locationId, versionId } = useParams();
  const history = useHistory();
  const [ questionsQuery ] = useState({
    filters: [[ 'class', '==', 'additional_negative' ]],
    orderBy: [[ 'order', 'asc' ]]
});

  const questions = useQuestions(locationId, versionId, questionsQuery.filters);


  useEffect(() => {
    ym('reachGoal','poll-negative')
  }, []);

  const loading = questions.isLoading;

  if (!questions.isLoading && questions.data.length === 0) {
    if (versionId) {
      history.push(`/${locationId}/version/${versionId}/result/negative`);
    }
    else {
      history.push(`/${locationId}/result/negative`);
    }
  }

  if (loading) {
    return <></>
  }

  return (
    <main>
      <title>Home Page</title>
      <Bonuses  label="poll_negative_bonuses"/>
      <div className={styles.wrapper}>
        <Logo className={styles.logo} />

        <Poll 
          questionsClass='additional_negative' 
          onPositive={() => { 
            if (versionId) {
              history.push(`/${locationId}/version/${versionId}/result/negative`);
            }
            else {
              history.push(`/${locationId}/result/negative`);
            }
            }}
        />
      </div>
      <Terms />
    </main>
  )
}

export default PollNegativePage
