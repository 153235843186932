import React, { useEffect, useState } from "react"
import Terms from '../components/terms';
import styles from "./thank-you.module.css"
import success from '../static/success.svg';
import ym from 'react-yandex-metrika';
import Parser from 'html-react-parser';
import Bonuses from '../components/bonuses';

import firebase from '../core/firebase';
import { useParams } from "react-router-dom";


// markup
const IndexPage = () => {

  const { locationId, versionId } = useParams();
  const [subtitle, setSubtitle] = useState('');
  const [title, setTitle] = useState('');

  firebase.locations.theme.getTheme(locationId, versionId).then((theme) => {

    if (!theme.IsLoading) {
      if (theme.result_subtitle) {
        setSubtitle(theme.result_subtitle);
      }
      if (theme.result_title) {
        setTitle(theme.result_title);
      }
    }
  });

  useEffect(() => {
    ym('reachGoal','result-positive')
  }, []);

  return (
    <main>
      <title>Home Page</title>
      <Bonuses label="result_bonuses" />
      <div className={styles.wrapper}>
        <img src={success} className={styles.logo} alt=""/>
        <h2 className={styles.heading}>{Parser(title)}</h2>
        <div className={styles.text}>
          {Parser(subtitle)}
        </div>
      </div>
      <Terms/>


    </main>
  )
}

export default IndexPage
