
import { ErrorCode, LavizorError } from '../sdk/errors';

export default class Links{
    constructor(firebase) {
        this.firebase = firebase;
    }

    subscribeToLinks = async (locationId, filters, orderBys, limit, successCallback, errorCallback) => {
        let query = this.firebase.db
        .collection('locations')
        .doc(locationId)
        .collection('links');

        if (filters) {
            filters.forEach((filter) => {
                query = query.where(...filter);
            });
        }

        if (orderBys && orderBys.length > 0) {
            orderBys.forEach((orderBy) => {
                query = query.orderBy(...orderBy);
            });
        }

        if (typeof limit === 'number')
            query = query.limit(limit);

        const unsubscribe = query.onSnapshot(
            (data) => {
                const result = data.docs.map((doc) => doc.data());

                if (successCallback)
                    successCallback(result || []);
            },
            (error) => {
                if (errorCallback) {
                    const generatedError = LavizorError.translateFirestoreError(
                        error,
                        new LavizorError('Failed to fetch links', ErrorCode.DatabaseOperationError, error)
                    );

                    errorCallback(generatedError);
                }
            }
        );

        return unsubscribe;
    }
}
