import React, { useEffect } from "react";
import styles from "./thank-you.module.css"
import success from '../static/success.svg';
import Bonuses from '../components/bonuses';
import Terms from '../components/terms';
import ym from 'react-yandex-metrika';


// markup
const ResultNegativePage = () => {

  useEffect(() => {
    ym('reachGoal','result-negative')
  }, []);

  return (
    <main>
      <title>Home Page</title>
      <Bonuses label="result_negative_bonuses" />
      <div className={styles.wrapper}>
        <img src={success} className={styles.logo} alt=""/>
        <h2 className={styles.heading}>Благодарим за обратную связь!</h2>
        <div className={styles.text}> 
          Ваш отзыв успешно отправлен директору компании. С Вами свяжутся в течении 24 часов
        </div>
      </div>
      <Terms/>

    </main>
  )
}

export default ResultNegativePage
