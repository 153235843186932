import React, { useEffect, useState } from "react"
import Poll from '../components/poll';
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Logo from '../components/logo';
import Terms from '../components/terms';
import Bonuses from '../components/bonuses';
import ym from 'react-yandex-metrika';
import { useQuestions } from '../core/hooks';

// markup
const PollNeutralPage = () => {
  const { locationId, versionId } = useParams();
  const history = useHistory();
  const [ questionsQuery ] = useState({
    filters: [[ 'class', '==', 'additional_negative' ]],
    orderBy: [[ 'order', 'asc' ]]
  });

  const questions = useQuestions(locationId, versionId, questionsQuery.filters);

  useEffect(() => {
    ym('reachGoal','poll-neutral')
  }, []);

  const loading = questions.isLoading;

  if (!questions.isLoading && questions.data.length === 0) {
    if (versionId) {
      history.push(`/${locationId}/version/${versionId}/result/neutral`);
    }
    else {
      history.push(`/${locationId}/result/neutral`);
    }
  }

  if (loading) {
    return <></>
  }

  return (
    <main>
      <title>Home Page</title>
      <Bonuses  label="poll_neutral_bonuses"/>
      <div className={styles.wrapper}>
        <Logo className={styles.logo} />
        <Poll 
          questionsClass='additional_neutral'
          onPositive={() => { 
            if (versionId) {
              history.push(`/${locationId}/version/${versionId}/result/neutral`);
            }
            else {
              history.push(`/${locationId}/result/neutral`);
            }
            }
          }
        />
      </div>
      <Terms />
    </main>
  )
}

export default PollNeutralPage
