import React, { useEffect, useState } from "react"
import Terms from '../components/terms';
import styles from "./index.module.css"
import upload from '../static/upload.png';
import Poll from '../components/poll';

import { useParams } from "react-router-dom";
import { useQuestions } from '../core/hooks';
import { useHistory } from "react-router-dom";
import ym from 'react-yandex-metrika';
import Bonuses from '../components/bonuses';

// markup
const PollAfterLinkPage = () => {
  const { locationId, versionId } = useParams();
  const history = useHistory();
  const [ questionsQuery ] = useState({
    filters: [[ 'class', '==', 'additional_result' ]],
    orderBy: [[ 'order', 'asc' ]]
});
  const questions = useQuestions(locationId, versionId, questionsQuery.filters);


  useEffect(() => {
    ym('reachGoal','poll-positive')
  }, []);

  const loading = questions.isLoading;

  if (!questions.isLoading && questions.data.length === 0) {
    if (versionId) {
      history.push(`/${locationId}/version/${versionId}/result`);
    }
    else {
      history.push(`/${locationId}/result`);
    }
  }
  
  if (loading) {
    return <></>
  }

  return (
    <main>
      <title>Home Page</title>
      <Bonuses label="poll_positive_bonuses"/>
      <div className={styles.wrapper}>
        <img src={upload} className={styles.upload} alt=""/>
        <Poll 
          questionsClass='additional_result'
          onPositive={() => { 
            if (versionId) {
              history.push(`/${locationId}/version/${versionId}/result`);
            }
            else {
              history.push(`/${locationId}/result`);
            }
          }
        }
        />
      </div>
      <Terms/>


    </main>
  )
}

export default PollAfterLinkPage
