import { useEffect } from 'react';

import firebase from '../firebase';
import useFirebaseReducer, { DefaultData, FirebaseReducerActions } from './use-firebase-reducer';

const useLinks = (locationId, filters, orderBys, limit) => {
    const [ data, dispatch ] = useFirebaseReducer(DefaultData);
    useEffect(() => {
        let unsubscribe = null;

        async function subscribe() {
            unsubscribe = await firebase.locations.links.subscribeToLinks(
                locationId,
                filters,
                orderBys,
                limit,
                (snapshot) => {
                    dispatch({
                        type: FirebaseReducerActions.Snapshot,
                        data: snapshot,
                        isExisting: !snapshot.empty
                    });
                },
                (error) => {
                    if (error?.code === 'permission-denied')
                        dispatch({ type: FirebaseReducerActions.Forbidden });
                }
            );
        }

        if (locationId)
            subscribe();

        return () => {
            if (unsubscribe)
                unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ locationId, filters, limit, orderBys ]);

    return data;
};

export default useLinks;
