import { Lavizor } from './sdk';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import Locations from './locations';
import Responses from './responses';
import ShortLinks from './short-links';
import QrCodes from './qr-codes';
import Versions from './versions';

class Firebase {
    constructor() {
        this.firebase = {
            internal: Lavizor.getFirebase(),
            auth: Lavizor.getFirebase().auth(),
            db: Lavizor.getFirebase().firestore(),
            functions: Lavizor.getFirebase().functions(),
            storage: Lavizor.getFirebase().storage()
        };

        this.Locations = new Locations(this.firebase);
        this.Responses = new Responses(this.firebase);
        this.ShortLinks = new ShortLinks(this.firebase);
        this.Versions = new Versions(this.firebase);
        this.QrCodes = new QrCodes(this.firebase);

    }


    get locations() {
        return this.Locations;
    }
    get responses() {
        return this.Responses;
    }
    get shortLinks() {
        return this.ShortLinks;
    }
    get versions() {
        return this.Versions;
    }
    get qrCodes() {
        return this.QrCodes;
    }
}

export default new Firebase();
