export default class Versions {
    constructor(firebase) {
        this.firebase = firebase;
    }

    getVersion = async (versionId) => {
        if (versionId) {
            return (await this.firebase.db
                .collection('versions')
                .doc(versionId)
                .get()).data();
        }

        return Promise.reject(new Error('Missing parameter "versionId"'));
    }
}
