
import React, { useState } from "react"
import firebase from '../../core/firebase';
import { useParams } from "react-router-dom";
import styles from "./index.module.css";

const Terms = () => {
  const { locationId, versionId } = useParams();
  const [terms, setTerms] = useState('');

  firebase.locations.theme.getTheme(locationId, versionId).then((theme) => {

    if (!theme.IsLoading) {
      if (theme.terms) {
        setTerms(theme.terms);
      }
    }
  }
  );

    return terms && <div className={styles.wrapper}>
      {terms}
    </div>
}

export default Terms