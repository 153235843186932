import mime from 'mime-types';
import { customAlphabet } from 'nanoid';

const utils = {
    generateRandomString: (length, alphabet) => {
        const actualAlphabet = alphabet || 'abcdefghijklmnopqrstuvwxyz1234567890';
        const nanoid = customAlphabet(actualAlphabet, length);
        const result = nanoid();

        return result;
    },
    generateFileName: ({ prefix, contentType }) => {
        const randomString = utils.generateRandomString(24);
        const result = `${prefix || ''}${randomString}${contentType ? `.${mime.extension(contentType)}` : ''}`;

        return result;
    },
}
export default utils;