import React from "react";
import styles from "./index.module.css";
import ShortLink from "../components/short-link";


// markup
const InitPage = () => {


  return (
    <main>
      <title>Home Page</title>
      <div className={styles.wrapper}>
          <ShortLink/>
      </div>
    </main>
  )
}

export default InitPage
