
export default class Theme {
    constructor(firebase) {
        this.firebase = firebase;
    }


    getTheme = async (locationId, versionId) => {
        if (locationId) {
            const baseTheme = (await this.firebase.db
                .collection('locations')
                .doc(locationId)
                .collection('public')
                .doc('theme')
                .get()).data();
            let versionTheme = null;
            
            if (versionId) {
                versionTheme = (await this.firebase.db
                    .collection('versions')
                    .doc(versionId)
                    .collection('public')
                    .doc('theme')
                    .get()).data();
            }
            return { ...baseTheme, ...versionTheme };
        }

        return Promise.reject(new Error('Missing parameter "locationId"'));
    }
}
