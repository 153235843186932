import React, {
    useState
} from "react"
import { useQuestions } from '../../core/hooks';
import styles from "./index.module.css";
import firebase from '../../core/firebase';
import Loader from '../loader';
import ImageUpload from '../image-upload';
import { useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';

const Poll = ({ questionsClass = 'default', onNeutral, onPositive, onNegative }) => {
    const { locationId, versionId } = useParams();
    const [currentQuestion, setCurrentQuestion ] = React.useState(0);
    const [currentAnswer, setCurrentAnswer ] = React.useState(null);
    const [required, setRequired ] = React.useState(false);
    const [answers, setAnswers ] = React.useState([]);
    const [cookies, ] = useCookies(['link','qr']);
    const [ questionsQuery ] = useState({
        filters: [[ 'class', '==', questionsClass ]],
        orderBy: [[ 'order', 'asc' ]]
    });
    console.log(cookies);
    const questions = useQuestions(locationId, versionId, questionsQuery.filters, questionsQuery.orderBy);
    if (questions.isLoading) {
        return <><Loader /></>;
    }
    const saveAnswerToDb = async () => {
        let answer = currentAnswer;
    
        const data = {
            type: 'answer',
            question: questions.data[currentQuestion].text,
            answer: answer,
            reference_id: cookies.qr?.id ?? cookies.link?.id,
            user: cookies.link?.data ?? {},
            qr: cookies.qr ?? {}
        }

        if (questions.data[currentQuestion].type === 'file') {
            const file =  data.answer
            data.answer = null;
            await firebase.responses.createFileResponse(locationId, data, file);
        }
        else {
            await firebase.responses.createResponse(locationId, data);
        }
    }
    const onClickNext = async () => {
        if (currentAnswer === null ||  currentAnswer === 0) {
            setRequired(true);
            return;
        }
        setRequired(false);
        await saveAnswerToDb();
        var finalAnswers = answers.concat([currentAnswer]);
        if (currentQuestion === questions.data.length - 1) {
            var lowest = Math.min.apply(null, finalAnswers);
            if (lowest <= 6 && onNegative) {
                onNegative();
                return;
            }
            if (lowest <= 8 && onNeutral) {
                onNeutral();
                return;
            }
            onPositive()
            return;
        }
        setAnswers(finalAnswers);
        setCurrentAnswer(null);
        setCurrentQuestion(currentQuestion+1);
    }

    const renderAnswerComponent = (question) => {
        switch(question.type) {
            case 'stars':
                return <div>
                    <fieldset className={styles.radioGroup}>
                        {[...Array(11)].map((x, i) => <div key={question.text + i}>
                            <input 
                                type="radio" 
                                id={`${i}-option`} 
                                className={styles.radio} 
                                value={i} 
                                name="group1"
                                onChange={(event) => {
                                    setCurrentAnswer(event.target.value);
                                    console.log(event.target.value);
                                }}
                            />
                            <label htmlFor={`${i}-option`} className={`${styles.circle}`} data-color={i<=6 ? 'red' : i<=8 ? 'orange' : 'green' }>{i}</label>
                            </div>
                        )}
                    </fieldset>
                </div>;
            case 'textarea': 
                return <textarea placeholder='Введите ваш отзыв...' className={styles.textarea} onChange={(event) => { setCurrentAnswer(event.target.value) }} />;
            case 'mobile': 
                return <div><input type="tel" placeholder='Введите ваш номер...' className={styles.input} onChange={(event) => { setCurrentAnswer(event.target.value) }} />
                        <label htmlFor="personal-data" className={styles.personalData}><input type="checkbox" id="personal-data"/> Согласен на обработку персональных данных</label>
                    </div>;
            case 'file': 
                return <ImageUpload onChange={(event) => { console.log(event.target.files[0]);setCurrentAnswer(event.target.files[0]) }} />;
            default:
                return <input type="text" className={styles.input} onChange={(event) => setCurrentAnswer(event.target.value)}/>;
        }
    }
    const question = questions.data[currentQuestion];
    if (question) {
    const questionsLeft = questions.data.length - currentQuestion - 1;
            return (<>
                <div className={`${styles.question} ${question.text.length > 80 ? styles.longQuestion : ''}`}>
                    {question.text}
                </div>
                <div className={styles.answer}>
                    {renderAnswerComponent(question)}
                </div>
                {required && <div className={styles.required}>Обязательно для заполнения</div>}
                <button className={`${styles.nextBtn} ${question.submit_green ? styles.greenBtn : ''}`} onClick={onClickNext}>
                    {questionsLeft > 0 ? 'Далее' : 'Отправить' }
                </button>
                { (questionsLeft > 0) && 
                <div className={styles.note}>
                    {questionsLeft === 1 ? `Остался 1 вопрос`: `Осталось еще ${questionsLeft} вопроса`}
                </div>
                }
            </>);
    }
    return <></>;
}

export default Poll;