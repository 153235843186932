
import React, { useState } from "react"
import firebase from '../../core/firebase';
import logoFile from '../../static/logo.jpg';
import { useParams } from "react-router-dom";

const Logo = ({ className }) => {
    const { locationId, versionId } = useParams();
    const [logo, setLogo] = useState('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

  firebase.locations.theme.getTheme(locationId, versionId).then((theme) => {

    if (!theme.IsLoading) {
      if (theme.logo) {
        setLogo(theme.logo);
      }
      else {
        setLogo(logoFile);
      }
    }
  }
  );

    return <img width="150" className={className} src={logo} alt=""/>
}

export default Logo