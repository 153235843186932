import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Loader from '../loader';

import { useLinks } from '../../core/hooks';
import styles from "./index.module.css";

const Links = ({ onClick }) => {
    const { locationId } = useParams();
    const [ linksQuery ] = useState({
        orderBy: [[ 'order', 'asc' ]]
    });
    const links = useLinks(locationId,null, linksQuery.orderBy);
    if (links.isLoading) {
        return <><Loader/></>;
    }

    return <div className={styles.wrapper}>
        {links.data.filter((link) => link.hidden !== true).map((link) => {
            return <button key={link.url} className={styles.link} onClick={() => onClick(link)}>
                <img className={styles.linkImg} src={link.logo} alt="" />
                <span className={styles.linkArrow}></span>
                </button>
        })}
    </div>
}

export default Links;