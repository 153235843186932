
import Questions from './questions';
import Links from './links';
import Theme from './theme';

export default class Locations {
    constructor(firebase) {
        this.firebase = firebase;

        this.Questions = new Questions(this.firebase);
        this.Links = new Links(this.firebase);
        this.Theme = new Theme(this.firebase);
    }

    get questions() {
        return this.Questions;
    }

    get links() {
        return this.Links;
    }

    get theme() {
        return this.Theme;
    }

    getLocation = async (locationId) => {
        if (locationId) {
            return this.firebase.db
                .collection('locations')
                .doc(locationId)
                .get();
        }

        return Promise.reject(new Error('Missing parameter "locationId"'));
    }

    subscribeToLocation = async (locationId, successCallback, errorCallback) => this.firebase.db
        .collection('locations')
        .doc(locationId)
        .onSnapshot(successCallback, (error) => {
            //logger.error('Failed in subscribeToUser', error);
            if (errorCallback) {
                errorCallback(error);
            }
        });
}
